import { useEffect, useRef, useState } from "react";
import { FaRegUserCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import { LuSearch } from "react-icons/lu";
import { Button, buttonVariants } from "common/components/ui/Button";
import { MdSwapVert } from "react-icons/md";
import { Skeleton } from "common/components/ui/Skeleton";
import { Link, useLocation } from "react-router-dom";
import { apiClient, cn, getCountryDetails, initials, truncate } from "utils";
import { assets } from "assets";
import { TbCopy, TbCopyCheck, TbLoader } from "react-icons/tb";
import { useFetchCurrentUser } from "hooks/useFetchCurrentUser";
import { usePlayers } from "hooks/usePlayers";
import { useCopyToClipboard } from "hooks/useCopyToClipboard";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "common/components/ui/DropdownMenu";
import { HiOutlineEllipsisVertical } from "react-icons/hi2";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
} from "common/components/ui/Dialog";
import { X } from "lucide-react";
import { DialogFooter, DialogHeader } from "common/components/ui/Dialog";
import toast from "react-hot-toast";
import InputField from "common/components/shared/InputeField";
import FilterUser from "common/components/shared/FilterUser";
import { IoFilterSharp } from "react-icons/io5";
import { useSearchParams } from "react-router-dom";
// import { setFilter } from "state/slices/appSlice";

export default function Players() {
  // const filter = useSelector((state: any) => state.app.filter);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchParams] = useSearchParams();
  const [selected_players, set_selected_players] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [blockingUser, setBlockingUser] = useState<any>({});
  const [user_to_block, set_user_to_block] = useState<TCredential | null>(null);
  const [open_block_confirmation, set_open_block_confirmation] =
    useState(false);
  const [email_setting, set_email_setting] = useState<any>(null);

  const itemsPerPage = 100;

  const { fetchUser, responseData: userData } = useFetchCurrentUser();
  const {
    getAllPlayers,
    players,
    isFetchingPlayers,
    total_players,
    searchPlayerByUsername,
    update_player_status,
    filter,
    set_filter,
  } = usePlayers();
  const { copiedItemId, copyToClipboard } = useCopyToClipboard();

  useEffect(() => {
    fetchUser();
  }, []);

  const location = useLocation();

  useEffect(() => {
    if (location.state?.settings) {
      set_email_setting(location.state.settings);
    }
    // const _email_setting_params = searchParams.get("email_setting");
    // if (_email_setting_params) {
    //   set_email_setting(JSON.parse(_email_setting_params));
    // }
  }, []);

  const [sending_email, set_sending_email] = useState(false);

  const sendEmail = async () => {
    try {
      if (sending_email) return;
      if (!selected_players.length) return;
      const _local_storage = localStorage.getItem("credential");
      if (!_local_storage || !JSON.parse(_local_storage || "").accessToken) {
        toast.error("UNAUTHORIZED");
        return;
      }
      set_sending_email(true);
      const response = await apiClient.post(
        "/send-email",
        {
          ...email_setting,
          selected_users: selected_players,
        },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(_local_storage || "").accessToken
            }`,
          },
        }
      );
      if (response.data.success) {
        toast.success("Email sent successfully");
        set_email_setting(null);
        set_selected_players([]);
      }
      set_sending_email(false);
    } catch (error: any) {
      console.log(error);
      toast.error(error.message || "Something went wrong");
      set_sending_email(false);
    }
  };

  useEffect(() => {
    if (userData.success) {
      getAllPlayers({
        size: itemsPerPage,
        page: currentPage,
      });
    }
  }, [userData.success, currentPage]);

  useEffect(() => {
    if (userData.success && filter) {
      if (searchTerm) {
        if (filter.order) {
          searchPlayerByUsername({
            username: searchTerm,
            order: filter.order!,
          });
        }
      } else {
        getAllPlayers({
          size: itemsPerPage,
          page: currentPage,
        });
      }
    }
  }, [filter]);

  const totalPages = Math.ceil(total_players / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      getAllPlayers({
        size: itemsPerPage,
        page: currentPage + 1,
      });
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage === 0) {
      return;
    }
    getAllPlayers({
      size: itemsPerPage,
      page: currentPage - 1,
    });
    setCurrentPage(currentPage - 1);
  };

  const handleSubmit = (e: any) => {
    if (searchTerm.trim() === "") return;
    e.preventDefault();
    if (e.key === "Enter") {
      searchPlayerByUsername({ username: searchTerm, order: "DESC" });
    }
  };

  const handle_block_user = async (userId: string, reason?: string) => {
    try {
      // deleteMatchById({ matchId });
      if (user_to_block) {
        const _local_storage = localStorage.getItem("credential");
        if (!_local_storage || !JSON.parse(_local_storage).accessToken) {
          toast.error("UNAUTHORIZED");
          return;
        }
        setBlockingUser({ [userId]: true });
        const response = await apiClient.put(
          `/users/block`,
          {
            userId,
            reason,
            status: user_to_block.status === "active" ? "blocked" : "active",
          },
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(_local_storage).accessToken}`,
            },
          }
        );

        if (response.data.success) {
          update_player_status(
            user_to_block.id,
            user_to_block.status === "active" ? "blocked" : "active"
          );
          toast.success(
            user_to_block.status === "active"
              ? "user blocked"
              : "user unblocked"
          );
          set_user_to_block(null);
          set_open_block_confirmation(false);
        } else {
          toast.error("Something went wrong");
        }
        setBlockingUser({ [userId]: false });
      }
    } catch (error: any) {
      console.log(error);
      setBlockingUser({ [userId]: false });

      toast.error(error.message || "OOOPPPSSS!!");
    }
  };

  return (
    <div className="flex flex-col overflow-y-auto">
      <BlockConfirmation
        handle_submit={handle_block_user}
        loading={user_to_block && blockingUser[user_to_block.id]}
        user={user_to_block}
        status={user_to_block?.status}
        open={open_block_confirmation}
        onOpenChange={() => {
          set_user_to_block(null);
          set_open_block_confirmation(false);
        }}
      />

      <header className="border-b p-4 border-border/30 h-[74px]">
        <div className="flex items-center h-full gap-3">
          <FaRegUserCircle className="size-6" />
          <h4>Players</h4>
        </div>
      </header>

      <section className="p-4">
        <div className="flex flex-col">
          <h4>Player Management</h4>
          <p className="text-xs text-muted-foreground">
            Manage player profiles, including blocking, unblocking, and deleting
            users.
          </p>
        </div>

        <div className="flex flex-col gap-6 mt-8">
          {/* Filter */}
          <div className="flex items-center justify-between">
            <h5 className="flex items-end gap-2">
              All Players
              <span className="text-muted-foreground text-base">
                {total_players}
              </span>
            </h5>

            {email_setting ? (
              <Button
                disabled={sending_email}
                className="px-20"
                onClick={sendEmail}
                variant={"default"}
              >
                {sending_email ? (
                  <>
                    <TbLoader size={22} className="mr-1.5 animate-spin" />{" "}
                    Publishing...
                  </>
                ) : (
                  "Publish Email"
                )}
              </Button>
            ) : null}

            <div className="flex items-center gap-4">
              <div className="h-10 w-[381px] relative">
                <LuSearch className="size-4 absolute top-1/2 -translate-y-1/2 left-3 text-muted-foreground" />
                <input
                  className="border border-border/30 focus:border-border ring-1 ring-black outline-none rounded-lg bg-secondary size-full pl-9 pr-4 text-foreground placeholder:text-muted-foreground text-sm"
                  placeholder="Search for players"
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    if (e.target.value.trim() === "") {
                      setTimeout(() => {
                        if (userData.success) {
                          getAllPlayers({
                            size: itemsPerPage,
                            page: currentPage,
                          });
                        }
                      }, 1000);
                    }
                  }}
                  onKeyUp={handleSubmit}
                />
              </div>

              <FilterUser
                on_apply={(value) => {
                  setSearchTerm("");
                  set_filter({
                    ...filter,
                    ...value,
                  });
                }}
                on_reset={() => {
                  setSearchTerm("");
                  set_filter({ order: "DESC" });
                }}
                status_value={filter?.status}
              >
                <Button variant={"secondary"}>
                  <IoFilterSharp className="size-4 mr-2" />
                  Filter
                </Button>
              </FilterUser>
            </div>
          </div>

          <div className="flex flex-col border border-border/30 rounded-2xl overflow-hidden">
            <div className="flex items-center [background:_linear-gradient(180deg,_#212121_0%,_#1F1F1F_40%,_#1D1D1D_80%)] px-4 py-3">
              {email_setting ? (
                <div className=" mr-3">
                  <input
                    onChange={(e) => {
                      if (e.target.checked) {
                        set_selected_players(players.map((mp) => mp.id));
                      } else {
                        set_selected_players([]);
                      }
                    }}
                    type="checkbox"
                  />
                </div>
              ) : null}
              <div className="flex items-center gap-2 w-[23%]">
                <p className="text-sm">Name</p>
                <MdSwapVert className="size-5 text-muted-foreground" />
              </div>
              <div className="flex items-center gap-2 w-[20%]">
                <p className="text-sm">Username</p>
                <MdSwapVert className="size-5 text-muted-foreground" />
              </div>
              <div className="flex items-center justify-center gap-2 flex-1">
                <p className="text-sm">Country</p>
              </div>
              <div className="flex items-center justify-center gap-2 flex-1">
                <p className="text-sm">Social Media</p>
              </div>
              <div className="flex items-center gap-2 flex-1">
                <p className="text-sm">Wallet Address</p>
              </div>
              <div className="flex items-center gap-2 justify-center flex-1">
                <p className="text-sm">Avg. Points</p>
                <button
                  onClick={() => {
                    if (filter?.order === "DESC") {
                      set_filter({ order: "ASC" });
                    } else {
                      set_filter({ order: "DESC" });
                    }
                  }}
                >
                  <MdSwapVert className="size-5 text-muted-foreground" />
                </button>
              </div>
            </div>

            <div className="flex flex-col">
              {isFetchingPlayers ? (
                Array.from({ length: 10 }).map((_, _key) => (
                  <div
                    key={_key}
                    className="h-[74px] flex items-center px-4 border-t border-border/30"
                  >
                    <div className="flex items-center py-4 gap-4 w-[23%]">
                      <Skeleton className="w-1/2 h-3 rounded-md" />
                    </div>
                    <div className="flex items-center py-4 gap-2 w-[20%]">
                      <Skeleton className="w-1/2 h-3 rounded-md" />
                    </div>
                    <div className="flex items-center py-4 justify-center gap-2 flex-1">
                      <Skeleton className="size-8 rounded-full" />
                      <Skeleton className="w-1/2 h-3 rounded-md" />
                    </div>
                    <div className="flex items-center py-4 justify-center gap-4 flex-1">
                      <Skeleton className="size-9 rounded-lg" />
                      <Skeleton className="size-9 rounded-lg" />
                    </div>
                    <div className="flex items-center py-4 gap-4 flex-1 group cursor-pointer">
                      <Skeleton className="w-1/2 h-3 rounded-md" />
                    </div>
                    <div className="flex items-center py-4 gap-4 flex-1 group cursor-pointer">
                      <Skeleton className="w-1/2 h-3 rounded-md" />
                    </div>
                  </div>
                ))
              ) : players?.length === 0 ? (
                <div className="aspect-[3.2] bg-secondary/50 flex items-center justify-center">
                  <h5 className="">No Player Found</h5>
                </div>
              ) : (
                players?.map((player: TCredential) => {
                  const country_details = getCountryDetails(
                    String(player?.nationality)
                  );
                  return (
                    <div
                      key={player?.id}
                      className="h-[74px] flex items-center px-4 border-t border-border/30 hover:[background:_linear-gradient(180deg,_#212121_0%,_#1F1F1F_40%,_#1D1D1D_80%)] transition-all"
                    >
                      {email_setting ? (
                        <div className="mr-3">
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              if (e.target.checked) {
                                if (!selected_players.includes(player.id)) {
                                  set_selected_players([
                                    ...selected_players,
                                    player.id,
                                  ]);
                                }
                              } else {
                                const new_selected = selected_players.filter(
                                  (ft) => ft !== player.id
                                );
                                set_selected_players(new_selected);
                              }
                            }}
                            checked={selected_players.includes(player.id)}
                          />
                        </div>
                      ) : null}
                      <div className="flex items-center gap-4 w-[23%]">
                        <div className="flex items-center justify-center size-10 rounded-lg border border-border/20 ring-1 ring-black [background:_radial-gradient(103.31%_110%_at_43.75%_21.25%,_rgba(255,_255,_255,_0)_0%,_rgba(255,_255,_255,_0.123)_55.28%,_rgba(255,_255,_255,_0.246)_100%)]">
                          <h5 className="font-medium">
                            {initials(
                              `${player?.firstname} ${player?.lastname}`
                            )}
                          </h5>
                        </div>

                        <Link
                          to={`/players/${player?.id}`}
                          className="flex flex-col flex-1"
                        >
                          <p className="text-sm text-foreground font-medium line-clamp-1">
                            {player?.firstname} {player?.lastname}
                          </p>
                          <span className="text-xs text-muted-foreground/80 font-medium line-clamp-1">
                            {player?.email}
                          </span>
                        </Link>
                      </div>
                      <div className="flex items-center gap-2 w-[20%]">
                        <p className="text-sm text-muted-foreground">
                          {player?.username}
                        </p>
                      </div>
                      <div className="flex items-center justify-center gap-2 flex-1">
                        {player?.nationality ? (
                          <>
                            <div className="text-3xl">
                              {country_details?.emoji}
                            </div>
                            <p className="text-sm text-muted-foreground">
                              {country_details?.name}
                            </p>
                          </>
                        ) : (
                          <p className="text-sm text-muted-foreground">N/A</p>
                        )}
                      </div>
                      <div className="flex items-center justify-center gap-4 flex-1">
                        {!player.x_profile_link &&
                        !player.telegram_user_name ? (
                          <p className="text-sm text-muted-foreground">N/A</p>
                        ) : (
                          [
                            {
                              condition: player.x_profile_link,
                              src: assets.svgs.xIcon,
                              alt: "X Icon",
                              link: `https://x.com/${player.x_profile_link}`,
                            },
                            {
                              condition: player.telegram_user_name,
                              src: assets.svgs.insIcon,
                              alt: "Instagram Icon",
                              link: `https://t.me/${player.telegram_user_name?.replace(
                                "@",
                                ""
                              )}`,
                            },
                          ].map(({ condition, src, alt, link }, index) =>
                            condition ? (
                              <Link
                                to={link}
                                key={index}
                                target="_blank"
                                className={buttonVariants({
                                  size: "icon",
                                  variant: "secondary",
                                })}
                              >
                                <img
                                  className="size-6"
                                  src={src}
                                  alt={alt}
                                  width={24}
                                  height={24}
                                />
                              </Link>
                            ) : null
                          )
                        )}
                      </div>

                      <div className="flex items-center gap-4 flex-1 group">
                        {player?.wallet ? (
                          <>
                            <p className="text-sm text-muted-foreground line-clamp-1">
                              {player?.wallet && truncate(player?.wallet)}
                            </p>
                            <span
                              className={cn(
                                "hidden group-hover:block cursor-pointer",
                                {
                                  block: copiedItemId === player?.id,
                                }
                              )}
                            >
                              {copiedItemId === player?.id ? (
                                <TbCopyCheck className="size-4 text-primary" />
                              ) : (
                                <TbCopy
                                  onClick={async () =>
                                    copyToClipboard(
                                      String(player?.wallet),
                                      player?.id
                                    )
                                  }
                                  className="size-4"
                                />
                              )}
                            </span>
                          </>
                        ) : (
                          <p className="text-sm text-muted-foreground line-clamp-1">
                            N/A
                          </p>
                        )}
                      </div>
                      <div className="flex items-center w-[7%] gap-2">
                        <p
                          className="text-sm text-muted-foreground line-clamp-1"
                          // className={`text-sm ${
                          //   player?.status === "active"
                          //     ? "text-green-500"
                          //     : "text-destructive"
                          // }`}
                        >
                          {Number(player.averagePoints).toFixed(2)}
                          {/* {player.totalPredictions && player.totalPoints
                            ? isNaN(
                                player.totalPoints / player.totalPredictions
                              )
                              ? (0).toFixed(2)
                              : (
                                  Number(player.totalPoints) /
                                  Number(player.totalPredictions)
                                ).toFixed(2)
                            : (0).toFixed(2)} */}
                        </p>
                      </div>
                      <div className="flex items-center">
                        <DropdownMenu>
                          <DropdownMenuTrigger
                            disabled={blockingUser[player.id]}
                            asChild
                          >
                            <div
                              role="button"
                              className={cn("cursor-pointer ml-auto", {
                                "pointer-events-none opacity-50":
                                  blockingUser[player.id],
                              })}
                            >
                              <HiOutlineEllipsisVertical className="size-4 text-muted-foreground hover:text-foreground" />
                            </div>
                          </DropdownMenuTrigger>

                          <DropdownMenuContent className="w-auto z-50">
                            <DropdownMenuGroup>
                              <DropdownMenuItem
                                disabled={blockingUser[player.id]}
                                onSelect={() => {
                                  set_open_block_confirmation(true);
                                  set_user_to_block(player);
                                }}
                              >
                                {player.status === "active"
                                  ? "Block"
                                  : "Unblock"}
                              </DropdownMenuItem>
                            </DropdownMenuGroup>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>

          {(total_players > itemsPerPage * (currentPage + 1) ||
            currentPage > 0) && (
            <div className="flex items-center justify-end mt-4">
              <div className="flex items-center gap-4">
                <p className="text-sm text-muted-foreground">
                  Page {currentPage + 1} of{" "}
                  {Math.ceil(total_players / itemsPerPage)}
                </p>
                <Button
                  size={"sm"}
                  variant={"secondary"}
                  onClick={handlePrevPage}
                  disabled={currentPage === 0}
                >
                  Prev
                </Button>
                <Button
                  size={"sm"}
                  variant={"secondary"}
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages - 1}
                >
                  Next
                </Button>
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
}

interface BlockProps {
  open: boolean;
  onOpenChange: () => void;
  handle_submit: (user_ud: string, reason?: string) => void;
  user: TCredential | null;
  loading: boolean;
  status?: string;
}

export const BlockConfirmation = ({
  user,
  onOpenChange,
  open,
  handle_submit,
  loading,
  status,
}: BlockProps) => {
  const reasonRef = useRef<HTMLTextAreaElement>(null);
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-[1043px] p-0 outline-none border-transparent bg-background rounded-xl overflow-hidden">
        <DialogTitle className="h-10 px-4 flex items-center justify-between [background:_linear-gradient(180deg,_#212121_0%,_#1D1D1D_79.91%,_#1C1C1C_100%),linear-gradient(0deg,_rgba(255,_255,_255,_0.01),_rgba(255,_255,_255,_0.01))]">
          <p className="text-sm">User - {user?.username}</p>
          <DialogClose>
            <X className="h-4 w-4" />
            <span className="sr-only">Close</span>
          </DialogClose>
        </DialogTitle>
        <DialogHeader className="px-4 py-6 max-h-[488px] overflow-y-auto">
          <h4>
            Are you sure you want to {status === "active" ? "block" : "unblock"}{" "}
            user?
          </h4>
          {status === "active" ? (
            <div className="my-10">
              <label
                htmlFor="reason"
                className="text-sm text-muted-foreground capitalize"
              >
                Reason
              </label>
              <textarea
                ref={reasonRef}
                id="reason"
                rows={10}
                className="size-full border border-border/30 outline-none rounded-xl text-base py-2 px-5 pr-10 bg-custom-gradient resize-none transition-colors disabled:pointer-events-none disabled:cursor-not-allowed placeholder:text-muted-foreground"
              ></textarea>
            </div>
          ) : null}
        </DialogHeader>
        <DialogFooter className="border-t border-border/30 w-full flex items-center justify-between p-4">
          <DialogClose asChild>
            <Button variant="secondary" disabled={loading}>
              Cancel
            </Button>
          </DialogClose>

          <Button
            disabled={loading}
            type="submit"
            variant={status === "active" ? "destructive" : "default"}
            onClick={() => {
              if (user) {
                handle_submit(user.id, reasonRef.current?.value);
              }
            }}
          >
            {loading ? (
              <>
                <TbLoader size={22} className="mr-1.5 animate-spin" />{" "}
                {status === "active" ? "blocking" : "unblocking"}...
              </>
            ) : status === "active" ? (
              "block user"
            ) : (
              "unblock user"
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
