import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { apiClient } from "utils";

export const getPlayers = createAsyncThunk(
  "app/getPlayers",
  async (
    { size, page, accessToken, filter }: TGetPlayersPayload,
    { rejectWithValue }
  ) => {
    try {
      let url = `/users?size=${size}&page=${page}`;
      if (filter) {
        const keys = Object.keys(filter);
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          if (key === "order" || key === "status")
            url = `${url}&${key}=${filter[key]}`;
        }
      }

      const response = await apiClient.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        return rejectWithValue(err.response?.data);
      }
      return rejectWithValue("An unexpected error occurred");
    }
  }
);

export const searchPlayer = createAsyncThunk(
  "app/searchPlayer",
  async (
    { query, accessToken }: { query: string; accessToken: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await apiClient.get(`/users/name/${query}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        return rejectWithValue(err.response?.data);
      }
      return rejectWithValue("An unexpected error occurred");
    }
  }
);

export const getPlayerData = createAsyncThunk(
  "app/getPlayerData",
  async ({ userId, accessToken }: TGetCurrentUser, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(`/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        return rejectWithValue(err.response?.data);
      }
      return rejectWithValue("An unexpected error occurred");
    }
  }
);

export const getPlayerStats = createAsyncThunk(
  "app/getPlayerStats",
  async ({ userId, accessToken }: TGetCurrentUser, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(`/score/stats/${userId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        return rejectWithValue(err.response?.data);
      }
      return rejectWithValue("An unexpected error occurred");
    }
  }
);
